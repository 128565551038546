import { useEffect, useState } from 'react'
import { Container, OverlayTrigger, Row, Tooltip } from 'react-bootstrap'
import { RiDeleteBinLine } from 'react-icons/ri'
import { Link } from 'react-router-dom'
import { iIcon, qr, shoppingCartIcon } from '../../../assests/Icons/icon'
import { notifyBugsnagError, openQrCode, showToast } from '../../../common/utils/Functions'
import DashboardLayout from '../../../components/layouts/DashboardLayout'
import ConfirmationModal from '../../../components/modals/ConfirmationModal/ConfirmationModal'
import PageHeading from '../../../components/pageHeading'
import SignsModal from '../../../components/pagesComponents/signs/SignsModal'
import ReactTable from '../../../components/reactTable/ReactTable'
import ThemeButton from '../../../components/theme/ThemeButton'
import useFetch from '../../../hooks/useFetch'
import styles from './signs.module.scss'

const Signs = () => {
    const [showSignForm, setShowSignForm] = useState<boolean>(false); // state to handle Add sign Form
    const [signList, setSignList] = useState<any[]>();
    const [selectedItem, setSelectedItem] = useState<any>();
    const [openDelete, setOpenDelete] = useState<boolean>(false); // state to handle Delete sign modal

    /**
 * @Pagination states
 * 1. pageLimit    :- store limit of data that will render on current page
 * 2. totalRecords :- store total number of data in partners api
 * 3. currentPage  :- store current page number
 */
    const [pageLimit, setPageLimit] = useState<number>(10);
    const [totalRecords, setTotalRecords] = useState<number>(0);
    const [currentPage, setCurrentPage] = useState<number>(1);


    const toggleAddSign = () => setShowSignForm(!showSignForm);
    /**
     * @function :-  to handle delete sign Modal on/open
    */
    const handleDelete = () => setOpenDelete(false);




    /**
     * initialise signs-list api
     */
    const [signsList, { response: signsListRes, error: signsListErr, loading: signsListLoading }] =
        useFetch("/common/qr-sign/list", {
            method: "post",
        });

    /** -------3. @deleteApi ------ */
    const [
        deleteApi,
        { response: deleteResponse, loading: deleteLoading, error: deleteError },
    ] = useFetch(`/common/qr-sign/${selectedItem?.uuid}`, {
        method: "delete",
    });
    useEffect(() => {
        if (deleteResponse) {
            showToast(deleteResponse.message as string, "success");
            try {
                signsList({
                    start: (currentPage - 1) * pageLimit,
                    limit: pageLimit,
                    is_active: null
                });
            } catch (e: any) {
                notifyBugsnagError(e, {
                    api: "signsList",
                });
            }
            handleDelete();
        }
        if (deleteError) {
            showToast(deleteError.message as string, "error");
        }
    }, [deleteResponse, deleteError]);

    /**
   * @Call all sign data using @listApi constant
   */
    useEffect(() => {
        const timer = setTimeout(() => {
            try {
                signsList({
                    start: (currentPage - 1) * pageLimit,
                    limit: pageLimit,
                    is_active: null

                });
            } catch (e: any) {
                notifyBugsnagError(e, {
                    api: "signsList",
                });
            }
        }, 500);
        return () => clearTimeout(timer);
    }, [currentPage, pageLimit]);

    useEffect(() => {
        if (signsListRes) {
            setTotalRecords(signsListRes.data?.totalRecords);
            const updatedSignList = signsListRes.data.list?.map(
                (item: any) => ({
                    description: item?.size_details?.name && item?.sign_details?.name
                        ? `${item.size_details.name} ${item.sign_details.name}`
                        : '--',
                    url: (
                        <span
                            onClick={() => {
                                openQrCode(item?.url as string);
                            }}
                        >
                            {" "}
                            {qr}{" "}
                        </span>
                    ),
                    uuid: item?.uuid,
                    linkToBuy: <span
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                            gap: '6px',
                            justifyContent: 'center',
                        }}
                    >
                        <Link
                            to={item?.sign_details?.link_to_buy}
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            {shoppingCartIcon}
                        </Link>
                        <OverlayTrigger
                            placement="top"
                            overlay={
                                <Tooltip id={`tooltip-${item.id}`} >
                                    <div style={{ textAlign: 'left' }} dangerouslySetInnerHTML={{ __html: item?.sign_details?.purchase_steps }} />
                                </Tooltip>
                            }
                        >
                            <span style={{ cursor: 'pointer' }}>{iIcon}</span>
                        </OverlayTrigger>
                    </span>
                })
            );

            setSignList(updatedSignList);
        }
        if (signsListErr) {
            showToast(signsListErr.message as string, "error");
        }
    }, [signsListRes, signsListErr]);


    const signHeading = [
        { key: "description", label: "Sign Description" },
        { key: "url", label: "Download", textAlign: 'center' },
        { key: 'linkToBuy', label: 'Link To Buy', textAlign: 'center' }
    ];

    const actionButtonOptions = [
        {
            name: "Delete",
            icon: <RiDeleteBinLine />,
            onClick: (item: any) => {
                setSelectedItem(item);
                setOpenDelete(true);
            },
        },
    ];

    return (
        <>
            <DashboardLayout>
                <Container className={styles.Container}>
                    <div className={styles.dash_page}>
                        <PageHeading
                            heading="Signs"
                            subHeading="Here you can see all of your created signs."
                        >
                            <ThemeButton onClick={() => toggleAddSign()}>Create a Sign</ThemeButton>
                        </PageHeading>
                    </div>

                    <Row>
                        <ReactTable
                            headings={signHeading}
                            data={signList || []}
                            actionColumn
                            actionButtonOptions={actionButtonOptions}
                            currentPage={currentPage}
                            itemPerPage={pageLimit}
                            totalItems={totalRecords}
                            setPerPageLimit={setPageLimit}
                            pageLimit={pageLimit}
                            setCurrentPage={setCurrentPage}
                            loading={signsListLoading || !signList}
                            pagination={true}
                        >
                            <div className={styles.org}>
                                <p>Signs</p>
                            </div>
                        </ReactTable>
                        {!signsListLoading && signList ?
                            <p style={{ textAlign: 'right' }}>For assistance, or to request a sign style or size not offered, please email <a href='mailto:support@toptipper.co ' >support@toptipper.co</a></p> : ''
                        }

                    </Row>

                    <SignsModal
                        show={showSignForm}
                        handleClose={toggleAddSign}
                        title={"Create A New Sign"}
                        signsList={signsList}
                    />

                    <ConfirmationModal
                        handleToggle={handleDelete}
                        title="Are you sure you want to delete
                        this sign?"
                        show={openDelete}
                        loading={deleteLoading}
                        confirm={() => deleteApi()}
                    />
                </Container>
            </DashboardLayout>
        </>
    )
}

export default Signs